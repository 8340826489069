import React from 'react';

import buttonBg from './img/button_bg.png';

import sendMotive from './img/send_motive.png';
import viewMotives from './img/view_motives.png';
import viewMissions from './img/view_missions.png';

export default function App() {
  return (
    <div className="home-page">
      <div className="menu background">
        <div>
          <img src={buttonBg} alt="Botones" />
        </div>
      </div>

      <div className="menu buttons">
        <div>
          <a href="/motivos-iglesia" target="_blank" rel="noopener noreferrer">
            <img src={viewMotives} alt="Ver Motivos de Oración" />
          </a>

          <a
            href="https://docs.google.com/forms/d/1B_VIsm3-eUx0f6edxZWC8I4KCxriOPO2uQ-J1C5niak/viewform?edit_requested=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={sendMotive} alt="Envia tus motivos de oración" />
          </a>

          <a href="https://laserrasosteniendolasoga.blogspot.com/" target="_blank" rel="noopener noreferrer">
            <img src={viewMissions} alt="Ver Motivos Misiones" />
          </a>
        </div>
      </div>
    </div>
  );
}
