import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col, Badge } from 'react-bootstrap';

import { getData } from './google.sheet';

import logo from './img/logo.jpeg';

const today = moment().startOf('day');

export default function App() {
  const [list, setList] = useState();

  useEffect(() => {
    const exec = async () => {
      const res = await getData();
      setList(res);
    };
    exec();
  }, []);

  console.log({ list });

  return (
    <Container fluid className="motives-church">
      <Row>
        <Col xs="12" className="logo">
          <img src={logo} alt="logo" />
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="text-center">
          <h1>Motivos de Oración Iglesia</h1>
        </Col>
      </Row>
      <Row className="motive-container">
        {_.map(list, ({ date, name, motive, memberOf, response }, idx) => {
          const isToday = today.unix() === date.startOf('day').unix();
          return (
            <Col key={idx} md="6" lg="4" xl="3">
              <Card className={isToday ? 'today' : undefined}>
                <Card.Body>
                  <Row>
                    {isToday && (
                      <Col xs="12">
                        <Badge pill variant="primary">
                          Motivo del día
                        </Badge>
                      </Col>
                    )}
                    <Col xs="12">
                      {date.format('dddd, D [de] MMMM YYYY')}{' '}
                      <Badge pill variant="secondary">
                        {memberOf}
                      </Badge>
                    </Col>
                    <Col xs="12">
                      <Card.Title className="title">{name}</Card.Title>
                    </Col>
                  </Row>

                  <Card.Text>{motive}</Card.Text>

                  {response && (
                    <div className="response">
                      <label className="response-label">Respuesta de Oración</label>
                      <Card.Text className="response-text">{response}</Card.Text>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}
