import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from '@reach/router';

import HomePage from './HomePage';
import MotivesChurch from './MotivesChurch';

import './index.scss';

ReactDOM.render(
  <section className="app-container">
    <Router>
      <HomePage path="/" />
      <MotivesChurch path="motivos-iglesia" />
    </Router>
  </section>,
  document.getElementById('root')
);
