import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/es';
import { GoogleSpreadsheet } from 'google-spreadsheet';

// const CLIENT_ID = '925515580845-7pu41si6bg7i3rq1d24ojd7kj4ebe3v0.apps.googleusercontent.com';
const API_KEY = 'AIzaSyBepH5p_7APD_wJkfBvlWtsFOlbk5CZ9tM';
// const SECRET = 'mF18dXZu78ike2XEhVh8w8ck';

const SHEET_KEY = '15h_rMYzYfuF_un4tDC4EyR4GhphodaV8K1mctKqFsXc';
// const SHEET_KEY = '1q339wQggt3AE7RzSES8YLZyMDtKXpfCEHAtWn4o4ou4';

export const getData = async () => {
  const doc = new GoogleSpreadsheet(SHEET_KEY);
  doc.useApiKey(API_KEY);
  await doc.loadInfo();
  const sheet = doc.sheetsByIndex[0];
  const rows = await sheet.getRows();

  return _.reverse(
    _.map(rows, row => ({
      date: moment(row._rawData[0], 'DD/MM/YYYY HH:mm:ss'),
      name: row._rawData[1],
      motive: row._rawData[2],
      memberOf: row._rawData[3],
      response: row._rawData[4]
    }))
  );
};
